@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

body {
	height: 70vh;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: block;
}


.About-header{
	height: 64vh;
    display: block;
    justify-content: center;
    align-items: center;
	margin-bottom: 45px;
}

.scrolling-rectangle {
	align-items: center;
	width: 95vw;
	height: 300px;
	overflow: auto;
	border: 10px solid #838383;
	padding: 10px;
}

.scrolling-content {
	width: 50px;
	height: 50px;
	background-color: #fed0172d;
	position: fixed;
	top: 50%;
	transform: translateX();
	transition: tranform 0.3s ease;
}

//arrows

.arrow-wrapper {
	z-index: -75;
}

.arrow-1 {
	width: 150px;
	height: 150px;
	rotate: 120deg;
	display: block;
	position: absolute;
	top: 111px;
	right: 64px;
}

.aboutMe-container {
	padding-top: 10vh;
 	column-count: 2;
	column-gap: 0%;
	margin: 0 50px 50px 0;
}

.img-container {
	filter: drop-shadow(15px 20px #fecF17); 
}

.aboutMe-img {
	max-height: 600px;
	max-width: 600px;
	object-fit: cover;
	width: 80%;
	height: 80%;
	clip-path: polygon( 90% 75%, 50% 100%, 10% 75%, 10% 25%, 50% 0,90% 25%);
	margin: 0 0 0 0;
	padding: 0 0 0 0;
}

//drawing name svg
.Svg-Container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	padding-right: 10%;
	
	svg {
		max-width: 45rem;
		width: 100%;
		height: auto;
		stroke: #1c1f4c;
		fill:none;
		stroke-width: .5;
		
		path {
			stroke-dasharray: 1500;
			stroke-dashoffset: -1500;

			@for $i from 1 through 15 {
				
				&:nth-of-type(#{$i}) {
					animation: draw 1000ms #{$i * 250}ms forwards, fillAnimation  4s forwards;
				}
			}
			
		}
		
	}
	
}

@keyframes draw {
		100% { stroke-dashoffset: 0; }
}

@keyframes fillAnimation {
	33% {
		fill: white;
	}
	100% {
		fill: #1c1f4c;
	}
	
}
