:root {
 
    --hex-width: 500px;
    --hex-between: 10px;
    --hex-color: #fecf17;
    --hex-resume-color: #04919c;
    --hex-height: calc(var(--hex-width) / 1.73);
    --hex-margin: calc(var(--hex-width) / 2);
    --hex-border: calc(var(--hex-margin) / 1.73);
    
}

*, :after, :before {
    box-sizing: border-box;
}

#section {
    padding: 100px;
}

// experience-wrapper

.experience-wrapper {
    margin: auto;
    justify-items: center;
    // transform: translateX(20%);
    position: relative;
    display: block;
    // padding: 50px 100px;
    margin-top: 10%;
    margin-bottom: 10%;
    width: 100%;
    padding-right: 96px;
    height: auto; //todo: update this once I understand how to flex the size of the wrapper
    max-width: 700px;
}

//svg waves

.experience-waves {
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.top {
    background-image: url('../public/experienceSVG1.svg');
}

.bottom {
    background-image: url('../public/experienceSVG2.svg');
}
// description wrapper 

.description-wrapper {
    padding: 0 100px;
    background-color: #04919c;
}

// header

.experience-header {
    color: white;
    display: block;
    font-family:  'Lato', sans-serif;
    position: relative;
    font-size:  3em;
    margin-bottom: 30px;
}

// description 
 .experience-description {
    color: white;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1.5em;
    padding: 0 5vw;
    font-weight: 600;
 }

// timeline

#experience-section .experience-wrapper #timeline {
    padding: 2em 0;
    //margin: 0 auto;
    width: 100%;
    max-width: 900px;
    max-height: 2500px;
    display: grid;
    grid-auto-rows: calc(var(--hex-width) - 28.87px / 2);
    // fix columns
    grid-gap: var(--hex-between) var(--hex-between);
    padding-bottom: var(--hex-border);
}

#experience-section .experience-wrapper #timeline:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    // width: 2px;
    // background-color: #04919c;
}

#experience-section .experience-wrapper #timeline:after {
    content: "";
    display: table;
    clear: both;
    width: 0;
    height: 0;
}

// timeline block

#experience-section .experience-wrapper #timeline .timeline-block {
    position: relative;
    margin: 0em 0;
}


#experience-section .experience-wrapper #timeline .timeline-block:after {
    content: "";
    display: table;
    clear: both;
}

//  timeline-content

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content {
    position: relative;
    //float: right;
    text-align: left;
    padding: 0px 3em 3em 3em;
    margin-left: 0;
    max-height: 300px;
    max-width: 500px;
    width: 500px;
    height: 300px;
    //clip-path: polygon( 90% 75%, 50% 100%, 10% 75%, 10% 25%, 50% 0,90% 25%);
    margin: 0 0 0 0;
    background-color: #fecf17;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content.resume {
    background-color: var(--hex-resume-color);
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content.resume:before {
    border-bottom: var(--hex-border) solid var(--hex-resume-color);
    bottom: 100%;
    left: -0%;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content.resume:after  {
    border-top: var(--hex-border) solid var(--hex-resume-color);
    top: 100%;
    width: 0;
    content: "";
    left: -0%;
}

.resume-download:hover {
    transition: all .1s ease-in;
    opacity: 90%;
    scale:calc(103%);
}

#experience-section .experience-wrapper #timeline .timeline-block:nth-child(even) .timeline-content {
    // float: left;
    margin-left: calc(var(--hex-width) / 2 + var(--hex-between) / 2);
}


#experience-section .experience-wrapper #timeline .timeline-block .timeline-content:before, .timeline-content:after{
    content: "";
    position: absolute;
    border-left: var(--hex-margin) solid transparent;
    border-right: var(--hex-margin) solid transparent;

    // top:24px;
    // right:100%;
 
    width: 0;
    // border: 15px solid transparent;
    // border-color: transparent;
}
#experience-section .experience-wrapper #timeline .timeline-block .timeline-content:before {
    border-bottom: var(--hex-border) solid var(--hex-color);
    bottom: 100%;
    left: -0%;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content:after  {
    border-top: var(--hex-border) solid var(--hex-color);
    top: 100%;
    width: 0;
    content: "";
    left: -0%;
}

// #experience-section .experience-wrapper #timeline .timeline-block .timeline-content:after {
//     content: "";
//     display: table;
//     clear: both;
// }

// timeline header 

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content .timeline-position {
	font-family: 'IBM Plex Sans', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    color: #1b242f;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content .timeline-resume {
	font-family: 'IBM Plex Sans', sans-serif;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    padding-top: 1em;
    color: white;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content .timeline-resume:hover {
    cursor: pointer;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content .timeline-header {
	font-family: 'IBM Plex Sans', sans-serif;
    text-transform: uppercase;
    font-size: 15px;
    color: #04919c;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-content .timeline-info {
    color: #1b242f;
}

// date

#experience-section .experience-wrapper #timeline .timeline-block .date {
    display: inline-block;
    position: absolute;
    width: 100%;
    top: 30%;
    left: 55%;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #1c1f4c;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2em;
}


#experience-section .experience-wrapper #timeline .timeline-block:nth-child(odd) .date {
    text-align: left;
    left: auto;
    right: -80%;
}

#experience-section .experience-wrapper #timeline .timeline-block:nth-child(even) .date {
    text-align: right;
    left: auto;
    right: 80%
}

// sprites 

#experience-section .experience-wrapper #timeline .timeline-block .timeline-img .sprite {
    display: block;
    width: 65px;
    height: 65px; 
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    background-repeat: no-repeat;
    background-size: 100%;
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-img .sprite.launchcode {
    filter: invert(100%);
	background-image: url(../public/rocket_launch.png);
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-img .sprite.palette {
    filter: invert(100%);
	background-image: url(../public/palette.png);
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-img .sprite.school {
    filter: invert(100%);
	background-image: url(../public/school.png);
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-img .sprite.terminal {
    filter: invert(100%);
	background-image: url(../public/terminal.png);
}

#experience-section .experience-wrapper #timeline .timeline-block .timeline-img .sprite.description {
    filter: invert(100%);
	background-image: url(../public/description.png);
}

@media (max-width: 1000px) {
    .experience-wrapper {
        padding-left: 0;
    }
    #experience-section .experience-wrapper #timeline .timeline-block .date {
        top: 52%;
        font-size: 1.7em;
    }
    .timeline-block:nth-child(odd) {
        right:auto;
        left: auto;
    }

    #experience-section .experience-wrapper #timeline .timeline-block{
        scale: 50%; 
        content: none;
    }

    #experience-section .experience-wrapper #timeline .timeline-block .timeline-content{
        text-align: center;
    }

    #experience-section .experience-wrapper #timeline .timeline-block .timeline-img .sprite {
        margin-bottom: 2.5em;
    }

    #experience-section .experience-wrapper #timeline .timeline-block .timeline-content .timeline-header,
    #experience-section .experience-wrapper #timeline .timeline-block .timeline-content .timeline-position {
        font-size: 30px;
 
        word-spacing: .5rem;
        line-height: 2rem;
        padding: 5px 5px 5px 5px;
    }

    .timeline-info {
        overflow: hidden;
		height: 0;
		width: 0;
		opacity: 0;
		font-size: 0%;
    }

    #experience-section .experience-wrapper #timeline {
        grid-auto-rows: 15%;
    }

    #experience-section .experience-wrapper #timeline .timeline-block .timeline-content:active{
    }


    @media (max-width: 650px) {

    .experience-wrapper {
        margin: 0px;
        padding-right: 0%;
    }

    #experience-section .experience-wrapper #timeline{
        display: list-item;
    }

    #experience-section .experience-wrapper #timeline .timeline-block{
        float: right;
        padding-right: 5vh;
    }

    #experience-section .experience-wrapper #timeline .timeline-block:nth-child(odd) .date {
        text-align: left;
        left: auto;
        right: -22em;
    }
    
    #experience-section .experience-wrapper #timeline .timeline-block:nth-child(even) .date {
        text-align: left;
        left: auto;
        right: -22em;
    }

    #experience-section .experience-wrapper #timeline .timeline-block:nth-child(even) .timeline-content {
        margin-left: 0;
    }
    }

}