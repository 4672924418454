
:root {
    --bg-color: #fff;
    --dark-color: #04919c;
    --light-color: #EEEEEE;
    --red-color: #ff5851;
    --body-font: 'IBM Plex Sans', sans-serif;
    --heading-font: 'IBM Plex Sans', sans-serif;
}

nav {
    background: var(--dark-color);
    font-family: var(--heading-font);
    color: var(--light-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    transition: all .5s ease-in-out;
}

a,
.welcome {
    cursor:default;
    text-decoration: none;
    display: block;
    color: #1b242f;
    font-weight: bold;
    font-style: italic;
    margin-left: 1rem;
    padding: 1em 0;
    padding-right: 1rem;
}

ul {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}


li {
    position:relative;
    flex: 0 0 auto;
    align-items: center;
    color: #999;
    height: 100%;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    color: #999;
    display: flex;
    font-size: 1.2em;
    height: 50px;
    justify-content: center;
    line-height: 16px;
    margin: 0;
    text-decoration: none;
    white-space: nowrap;
}
.liNav::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background-color: white;
    width: 0%;
    height: 4px;
    transition: all 0.5s;
}

.liNav:hover::after{
    width: 100%;
}


// Mobile
@media (max-width: 950px) {
  .welcome {
    display: none;
  }

  .liNav {
    font-size: 14px;
  }

  nav {
    display: block;
  }
  ul {
    justify-content: center;
    padding:  0 0 0 0;
    padding-inline-start: 40px 0 0 40px;
  }
}

@media (max-width: 500px) {
  .liNav {
    padding-left: 0%;
  }
}