@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=MuseoModerno:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Hind:wght@600&display=swap');

:root {
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa', sans-serif;
}

input {
  font-family: 'Comfortaa', sans-serif;
}

textarea {
  font-family: 'Comfortaa', sans-serif;
}

button {
  font-family: 'Comfortaa', sans-serif;
}

header {
  font-size:  34px;
  font-family: 'Hind', sans-serif;
}