@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

body {
	font-size: 14px;
	line-height: 24px;
	height: 90vh;
	margin-bottom: 20%;
}

::marker {
	content: none;
}

.content-width {
	padding-left: 96px;
  	padding-right: 96px;
}

.skills-header {
	font-family:  'Lato', sans-serif;
	position: relative;
	font-size:  3em;
	margin-bottom: 30px;
}

.skills-body {
	margin-top: 10%;
	padding-bottom: 10%;
	margin-bottom: 4
	0%;
}

.bold {
	font-weight: 500;
}

.flex { // todo: clean up all items that use flex and add this instead

 display: flex;
 flex-direction: column;
 justify-content: center;
}

.flex-50 {
	display: block;
	flex: 1 1 50%;
	max-height: 100%;
	max-width: 50%;
	box-sizing: border-box;
}

.flex-row {
	flex-direction: row;
}
// Fade in skills

.fade-in {
	color: #1c1f4c;
	background-color: none;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 1s ease-in, transform 1s ease-in;
	transform: translateY(10%);
}

.fade-in.appear {
	opacity: 1;
}

// Skills

.skills:hover{
	transition: all .5s ease-in;
	background-color: #fecf17;
	clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0 100%);
	cursor: pointer;
}

.activeSkill{
	transition: all .5s ease-in-out;
	background-color: #fecf17;
	clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0 100%);
}

// Fade in From right

.from-right {
	opacity: 0;
	transform: translateX(50%);
	transition: transform 1.2s ease-in, opacity .5s ease-in;
}

.from-right.appear {
	opacity: 1;
	transform: translateX(0);
	transition: transform 1s ease-in opacity 1.6s ease-in;
}

.from-right.active {
	opacity: 0;
	transform: translateX(-100vw);
	transition: transform 1.2s ease-in, opacity .7s ease-in;
}

.columns {
	width: 100%;
	display: flex;
	box-shadow: 0 0 #e1e5e9, 0 4px 24px rgba(0, 0, 0, .08);
	border-radius: 25px;
}

.img-container {
	padding: 0 0 0 0;
	margin: 0 0 0 0;
}

.mask1 {
	position: relative;
	background-position: center;
	background-size: 300%;
	mask-repeat: none;
}

.bar-wrapper {
	align-items: center;
	display: block;
	box-sizing: border-box;
	width: 90%;
	background-size: cover;
	margin: 0 0 0 0;
}

.skill-bar {
	position: relative;
	align-items: center;
	background-color: #eeeeee;
	height: 30px;
	width: 100%;
	background-size: cover;
	display: flex;
	margin: 0 0 15px 0;
}

.fill {
	left: 0;
	position: absolute;
	top: 0;
	background-color: #04919c;
	height: 30px;
	width: 0%;
	opacity: 1;
	padding: 0;
	transition: 1.1s 0.2 width ease-in-out;
}

.skill-tag{
	font-family: 'Lato', sans-serif;
	left: 0;
	background-color: #04919c;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: #fff;
	height: 100%;
	width: 150px;
}

.skill-content {
	margin-top: 5%;
	font-size: 3ch;
	line-height: 1.5;
	text-overflow: ellipsis;
}

.skill-image {
	display: flex;
	flex-grow: 1;
	width: 100%;
	justify-items: center;
}

.clipped {
	background-image: inherit;
	max-width: 60vw;
	max-height: 600px;
	height: 100%;
	margin: auto;
	border-radius: 24px;
	object-fit: cover;
	background-size: 6000px auto;
	clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 10% 100%);
	width: 100%;
	float: left; 
}

.content-container {
	display: block;
	justify-content: center;
	justify-items: center;
	margin: auto;
	padding: 15px 20px 15px 20px;
	position:relative;
	text-align: left;
	overflow: hidden;
}

.grid {
	margin-left: none;
	margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
	border: solid white;
    justify-content: center;
}

.grid-rectangle {
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: column;
    height: 10vh;
    align-items: center;
    margin: 0% 0% 5% 0%;
    font-size: 350%
}

//mobile
@media (max-width: 1300px ) {
	.clipped {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
		object-position: 50% 50%;
		max-width: none;
		background-image: inherit;
		height: 100%;
		object-fit: cover;
		width: 100%;
		background-size: 600px auto;
	}
}

@media (max-width: 950px) {

	body {
		margin-bottom: 30%;
	}

	.content-width {
		padding-left: 3%;
		padding-right: 3%;
	}

	.grid {
		display: list-item;
	}

	li {
		margin: 0 0 0 0;
		padding-left: 5%;
		font-size: 125%;
	}
	.fade-in {
		justify-content: left;
	}

	//todo: clean up
	.skills:hover {
		clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0 100%);
	}

	.skills-header {
		font-size: 170%;
		margin-bottom: 10%;
	}
	
	.content-container {
		margin: 0 0 0 0;
	}

	.skill-content {
		font-size: 130%
	}

	.activeSkill{
		transition: all .5s ease-in-out;
		background-color: #fecf17;
		clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0 100%);
	}

	.clipped {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
		object-position: 50% 50%;
		max-width: none;
		background-image: inherit;
		object-fit: cover;
		background-size: 900px auto;
	}
		
	.skill-image {
		overflow: hidden;
		width: 50%;
		max-height: 450px;
		border-radius: 24px;
	}

	.flex-50 {
		max-width: 100%;
	}

	.skill-tag{
		font-family: 'Lato', sans-serif;
		left: 0;
		align-items:flex-start;
		background-color: transparent;
		flex-direction: column;
		color: black;
		height: 100%;
		width: 150px;
	}

	.skill-bar {
		background-color: none;
	}

	.bulletText {
		overflow: hidden;
		height: 0;
		width: 0;
		opacity: 0;
		font-size: 0%;
	}
}

@media (max-width: 750px) {
	body {
		margin-bottom: 40%;
	}
}

@media (max-width: 550px) {
	body {
		margin-bottom: 60%;
	}
}