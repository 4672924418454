@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Comfortaa:wght@700&display=swap');

h4 {
    font-family:  'Lato', sans-serif;
    font-weight: 700;
    color: #edebd9;
    padding: 15px 0 25px 0;
    font-size: 35px;
}
.container {
    margin: auto;
    padding: 0px 0 25px 0;
    text-align: center;
    background-color: #1c1f4c;
    display: flex;
    flex-direction: column;
}

// WAVES
.block {
    background-color: red;
    height: 10px;
    width: 10px;
}
.canvas {
    position:fixed;
    top: 0;
    left: 0;
}

// CONTACT FORM

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    color: #edebd9;
    background-color: #16183b;
    border: none;
    outline: none;
    width: 50vw;
    padding: 10px 15px;
    margin-bottom: 3px;
    box-sizing: border-box;
}

textarea {
    color: #edebd9;
    background-color: #16183b;
    border: none;
    box-sizing: border-box;
    display: block;
    min-height: 150px;
    outline: none;
    padding: 10px 15px;
    width: 50vw;
    resize: none;
}


.button-submit {
    font-family: 'Comfortaa', sans-serif;
    font-weight: 900;
    background-color: #04919c;
    border-radius: 35px;
    color: #edebd9;
    float: right;
    outline: none;
    border: 0;
    padding: 10px 30px;
    margin: 10px 0 0 0;
}

.button-submit:hover {
    background-color: #028089;
    cursor: pointer;
}
