.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #edebd9;
}

.app-body{
	overflow-x: hidden;
}

.waves-svg {
  margin-top: 3%;
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../public/contactSVG.svg');
}

.sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
  backdrop-filter: blur(8px);
  background: none;
  transition: all .5 ease-in-out;
  border-bottom: 3px solid #fecf17;
  color: red;
}


// Mobile
@media (max-width: 950px) {
  ul {
    padding: 0 0 0 0;
  }
}