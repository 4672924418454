
footer {
    text-align: center;
    background-color: #1b242f;
    color: #000;
    padding: 70px 0 50px 0;
}
p {
    color: #04919c;
}
.iconLink {
    margin: auto;
    width: fit-content;
    padding: 0;
}
.footer-img {
    filter: invert(100%);
    max-height: 35px;
    color: white;
}

.iconLink:hover {
    cursor: pointer;
    transform: scale(140%);
    transition: transform 200ms ease-in;
}

.row {
    flex-direction: row;
    display: flex;
    margin: auto;
    width: 10vw;
}

